import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
// import LicensedPart from "../../components/services/template/Licensed"
// import TitledPart from "../../components/services/template/title"
import ContentPart from "../../components/services/template/content"
import FireAlarmHero from "../../components/heros/fireAlarmHero"

const TitleText = `
	Fire Alarm Inspections
`

const FireAlarmInspectionPage = () => (
	<Layout>
		<Seo title={TitleText} description="Immediate warnings to people nearby are necessary when fires break out because they can spread rapidly and be deadly. In addition, fire alarms save lives when fires cannot be contained or extinguished at their source." />

		<FireAlarmHero />

		<ContentWrapper>
			{/* <LicensedPart /> */}
			{/* <TitledPart>{TitleText}</TitledPart> */}

			<ContentPart>
				<p>Immediate warnings to people nearby are necessary when fires break out because they can spread rapidly and be deadly. In addition, fire alarms save lives when fires cannot be contained or extinguished at their source.</p>
				<p>Provincial, federal, and industrial regulations mandate monthly and annual fire alarm inspections because their operation is critical for public safety.</p>
				<h2>In-Suite Testing</h2>
				<p>In many multi-unit residential buildings, fire alarm systems are installed in-suite and wired into the central alarm system for the building. This equipment is separate from battery-operated smoke detectors typically found on the ceiling near the kitchen. Both are inspected annually. Inspection and testing thus require unit access.</p>
				<p>We provide certified technicians you can trust to perform in-suite fire alarm testing.</p>
				<h2>Annual Fire Alarm Inspections</h2>
				<p>Local, provincial, and federal agencies all require complete system testing of fire alarms every year. In addition, inspections on systems ensure that they meet code.</p>
				<p>We inspect all primary, ancillary, auxiliary components and connections are inspected for function and access. We check that the emergency evacuation system and annunciators are correctly working. As well as ensure the remote connection to the fire department is operational. Signal components of the monitoring system also get tested for reception by the monitoring company.</p>
				<p>We provide meticulously detailed documentation for you to provide to owners, insurance companies, the fire department, and other authoritative agencies.</p>
				<h2>Monthly Fire Alarm System Inspections</h2>
				<p>Monthly inspections are a quick process to check that your alarm system functions correctly throughout the year.</p>
				<p>The power lamp, fire bells, pull stations, trouble indicators, emergency telephones, pagers, the connection of annunciators to the correct pull station, and the battery terminal connections for the standby power system are the components evaluated in the monthly inspection.</p>
				<p>Performing monthly fire alarm inspections require extensive knowledge to ensure the safety of others we provide.</p>
				<p><Link to="/contact" title="go to fire alarm inspections services contact page">Contact us today for in-suite testing.</Link></p>
			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default FireAlarmInspectionPage